import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query getAllDefinitions {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___title }
        filter: { frontmatter: { draft: { ne: true } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Dictionary"
        description="Learn about the most popular terms in online advertising"
      />
      <h1>Dictionary</h1>
      <ul>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <li>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}
